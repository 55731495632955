
import PropertyHeaderMolecule from "~/components/presentational/molecules/PropertyHeader.vue";
import VLoadingAtom from "~/components/presentational/atoms/VLoading";
import {AppLogic} from "~/logics/app-logic";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'PropertyHeaderContainer',
  components: {
    PropertyHeaderMolecule,
    VLoadingAtom,
  },
  computed: {
    ...mapGetters({
      systemMessage: 'app/systemMessage',
      loginUser: 'app/loginUser',
      shopData: 'app/shopDataFromKibou',
      headLoading: 'app/headLoading',
    }),
  },
  async created() {
    // #1792 TODO: handle when kibou not exist
    if (!this.$route.query.kibou || (this.$route.query.kibou && _.isNaN(parseInt(this.$route.query.kibou)))) {
      this.setShopDataEmpty();
      this.$router.replace({'query': null});
    }
    if ((this.$route.query.kibou || this.shopData.kibou) && !_.isNaN(parseInt(this.$route.query.kibou))) {
      await this.getShopData();
    }
    await this.getPropertyData();
  },
  methods: {
    //スタッフ・店舗情報の取得
    ...mapMutations({
      setShopDataEmpty: 'app/setShopDataEmpty'
    }),
    async getShopData() {
      await AppLogic.selectShopDataFromKibou(this, {
        kibou: Number(this.$route.query.kibou ?? this.shopData.kibou),
      });
    },
    //物件リストの取得
    async getPropertyData() {
      await AppLogic.selectProperty(this, {
        company: this.loginUser.user_kind !== '0' ? this.shopData.company ?? this.loginUser.company : null,
        property: Number(this.$route.params.property)
      });
    },
  }
}
