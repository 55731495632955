
import {AppLogic} from "~/logics/app-logic";

import GlobalGetters from "~/mixins/GlobalGetters";
import VLoading from "~/components/presentational/atoms/VLoading";
import VSubMenuAtom from "~/components/presentational/atoms/VSubMenu";
import {getStaffMail} from "../middleware/authenticated";
import SecureLS from "secure-ls";

import constMenu from '~/const/menu';

export default {
  components: {
    VLoading,
    VSubMenuAtom,
  },
  mixins: [
    GlobalGetters
  ],
  data() {
    return {
      //選択店舗・スタッフ
      activeMenu: false,
      isOpened: false,
      isSpMenuOpen: false,
      subPage: '',
      subPageName: {
        maisoku: 'マイソク出力',
        export: '出力ファイル一覧',
        template: 'テンプレート管理'
      },
      path: ''
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        this.getSubPageName(to.path);
        this.isSpMenuOpen = false
      }
    },
    loginUser: {
      handler: async function (newLoginUser) {
        //遷移した際の店舗リスト、スタッフリスト取得
        await this.changeShop()
      },
      deep: true
    },
  },
  async beforeCreate() {
    //ログインスタッフのメールアドレス取得
    const staffMail = await getStaffMail();
    //店舗・スタッフ情報を取得
    if (staffMail) {
      await AppLogic.selectStaffFromMail(this, {mail: staffMail, shop: this.loginUser.shop});
    } else {
      this.$store.dispatch('app/setStaffLoading', false);
      this.$store.dispatch('app/setHeadLoading', false);
    }
    this.getSubPageName(this.$route.path);
  },
  beforeDestroy() {
    window.removeEventListener('load', this.getSubPageName(this.$route.path));
  },
  computed: {
    menuObject() {
      return Object.assign(constMenu);
    },
    defaultSelectedShop: {
      get() {
        if(this.loginUser){
          return this.shopMapList.find(shop => shop.key === this.loginUser.shop);
        }
        return this.shopMapList[0] ?? null;
      },
      set(value) {
        return value;
      }
    },
  },
  methods: {
    // Change shop event
    async changeShop(event) {
      if (event) {
        await AppLogic.changeShopGlobal(this, {shopId: Number(event.key), shopCompanyId: event.company});
        return;
      }
      await AppLogic.changeShopGlobal(this, {shopId: this.loginUser.shop, shopCompanyId: this.loginUser.company});
    },
    navToggle: function (menu) {
      this.activeMenu = this.activeMenu === menu ? false : menu
    },
    getSubPageName: function (page_path) {
      if (page_path === '/') {
        this.path = 'top';
      } else {
        const pathArray = _.filter(page_path.split('/'));
        if (pathArray[0] === 'maisoku') {
          this.path = 'maisoku'
          if (!_.isNil(pathArray[1])) {
            this.path = pathArray[1];
          }
        } else {
          this.path = pathArray[0];
        }
      }
      if (this.subPageName[this.path]) {
        this.subPage = this.subPageName[this.path];
      } else {
        this.subPage = this.subPageName['top'];
      }
    },
    toggleMenu: function () {
      this.isSpMenuOpen = !this.isSpMenuOpen;
    },
    logout: async function () {
      const ls = new SecureLS({isCompression: false});

      ls.remove('cms');
      localStorage.removeItem('ags_sso_staff');
      window.location.replace(process.env.TRUST_LOGIN_SIGN_OUT_URL);
    },
    // Shop filter on header
    shopFilter(item, queryText) {
      const filterValue = item.value.toLowerCase()
      const searchText = queryText.toLowerCase()

      // Reminder: Watch the hooks.
      return (
        filterValue.indexOf(searchText) > -1
      )
    }
  }
}
