

import {AsYouType} from "libphonenumber-js";

export default {
  name: 'PropertyHeaderMolecule',
  components: {
  },
  props: {
    staffData: {
      type: Object,
      required: true,
    },
    shopData: {
      type: Object,
      required: true,
    }
  },
  computed: {
    isShopDataEmpty() {
      return _.isEmpty(this.shopData)
    },
  },
  methods: {
    //店舗名
    getShopName(shopData) {
      return shopData.name ? shopData.name : '';
    },
    //店舗URL
    shopURL(shopData) {
      return shopData.url ? shopData.url : '';
    },
    //企業名
    getCompanyName(companyData) {
      return companyData.name ? companyData.name : '';
    },
    //電話番号
    getTellNumber(shopData) {
      if (shopData.tel_free) {
        return new AsYouType('JP').input(shopData.tel_free);
      } else {
        return shopData.tel ? new AsYouType('JP').input(shopData.tel) : '';
      }
    },
    //定休日
    getHoliday(shopData) {
      return shopData.holiday ? `定休日／${shopData.holiday}` : '';
    },
    //営業時間
    getOpenTimeZone(time) {
      return time ? `${time.slice(0, 2)}：${time.slice(2)}` : '';
    },
  }
}
