
export default {
  name: 'VonInputText',
  props: {
    value: {
      type: [String, Number],
      require: true
    },
    placeholder: {
      type: String,
      require: true
    },
    input: {
      type: Event,
      require: true
    },
    type: {
      type: String,
      require: false,
      default: () => 'text',
    },
  }
};
