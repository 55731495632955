// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/common/icon_search-blue.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c-keywordTextV2[data-v-260eb2e4]{align-items:stretch;border:2px solid #c9c9c9;border-radius:3px;display:flex;justify-content:flex-end;min-height:35px;position:relative;width:100%}.c-keywordTextV2[data-v-260eb2e4]:focus-within{outline:auto}.c-keywordTextV2__keyword[data-v-260eb2e4]{background:#fff;border:none!important;font-size:14px;line-height:1;padding:5px 13px;width:100%}.c-keywordTextV2__keyword[data-v-260eb2e4]:focus{outline:none}.c-keywordTextV2__button[data-v-260eb2e4]{flex-shrink:0;position:relative;transition:opacity .4s;width:32px}.c-keywordTextV2__button[data-v-260eb2e4]:before{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain;bottom:0;content:\"\";height:18px;left:0;margin:auto;position:absolute;right:0;top:0;width:17px}.c-keywordTextV2__button[data-v-260eb2e4]:disabled{cursor:not-allowed}.c-keywordTextV2__button[data-v-260eb2e4]:not(:disabled):hover{opacity:.5}@media screen and (max-width:767px){.c-keywordTextV2[data-v-260eb2e4]{min-height:28px}.c-keywordTextV2__keyword[data-v-260eb2e4]{font-size:12px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
