
export default {
  name: 'VSubMenu',
  props: {
    menuName: {
      type: String,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMenuBalloon: false,
    }
  },
  methods: {
    showMenuBalloon() {
      this.isMenuBalloon = !this.isMenuBalloon;
    },
  }
}
