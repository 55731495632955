
import {mapGetters} from 'vuex';

export default {
  name: 'TheMain',
  computed: {
    ...mapGetters({
      systemMessage: 'app/systemMessage',
    }),
  }
}
