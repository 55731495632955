
import VonInputText from '~/components/presentational/atoms/VonInputText.vue'

export default {
  name: 'KeywordTextAtomV2',
  components: {
    VonInputText
  },
  props: {
    value: {
      type: String,
      require: true
    },
    className: {
      type: String,
      require: false
    },
    placeholder: {
      type: String,
      require: false,
      default: () => 'クイック顧客検索',
    },

  },
  computed: {
    keyword: {
      get() {
        return this.$props.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    keyword(newKeyword) {
      if (newKeyword?.match(/　/g)) this.keyword = newKeyword?.replace(/　/g, " ");
    }
  },
  methods: {
    // Enterで検索
    enterSubmit(event) {
      // 日本語入力中のEnterキー操作は無効にする
      if (event.keyCode !== 13) return;

      this.$emit('search-keyword');
    },
  }
};
